body {
    font-family: "Raleway", Arial, Helvetica, sans-serif;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #dcdcdc;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #bcbcbc;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #bcbcbc;
}

#header{
    background-color: #9254de;
    display: flex;
    align-items: center;
    height: 68px;
}

#header > #title{
    flex: 1;
    display: flex;
    justify-content: center;
}

#header > #user{
    display: flex;
    justify-content: center;
    align-items: center;
}

#header > #user > div{
    margin-left: 14px;
    margin-right: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#header > #user > div > span{
    line-height: normal;
    color: #ffffff;
}

#goHome{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
}

#goHome > span{
    line-height: 1;
    margin: 0 4px;
}

#title > span{
    font-size: 22px;
    color: #ffffff;
}

.loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

@media only screen and (max-width: 768px) {
    #userInfo, #goHome > span:nth-child(2){
        display: none !important;
    }

    #header{
        padding: 0 24px;
    }
}