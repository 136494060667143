.login-form {
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
    -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
    -moz-box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
    border-radius: 8px;
}

.login-content{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media only screen and (min-width: 768px) {
    .login-form {
        width: 768px;
    }
}
