.carousel-image-content img{
    width: 100%;
    object-fit: cover;
    filter: blur(6px);
    -webkit-filter: blur(6px);
    height: calc(100vh - 90px);
}

.slick-list{
    height: calc(100vh - 90px);
}

#container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#menu{
    display: flex;
}

.menu-item{
    border: 1px solid #7e49bf;
    cursor: pointer;
    background-color: #9254de;
    margin: 0 4px;
    width: 140px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    transition: all .2s ease-in-out;
}

.menu-item:hover{
    background-color: #9e67e0;
    transform: scale(1.05);
}

.menu-item span{
    text-align: center;
    font-size: 16px;
    color: #ffffff;
}

#footer{
    height: 50px;
}

@media only screen and (max-width: 1368px) {
    #container{
        width: 100%;
        top: 120px;
        left: 50%;
        transform: translate(-50%);
    }

    #menu{
        height: auto;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .menu-item{
        margin-bottom: 12px;
        height: auto;
        padding: 12px;
        width: 85%;
    }
}
